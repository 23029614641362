import { Session } from "next-auth";
import { useSession } from "next-auth/react";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { FTLEvent, FTLTruck, Week } from "../types";

const TrucksContext = React.createContext<{
  trucks: {[key: string]: FTLTruck},
  myTruck?: FTLTruck,
  getTrucks: (market?: string) => Promise<void>,
  getTruck: (truckId: string) => Promise<FTLTruck>,
  getMyTruck: () => Promise<FTLTruck>,
}>({
  getTrucks: (market?: string) => { return Promise.resolve() },
  getTruck: (truckId: string) => { return Promise.resolve({} as FTLTruck) },
  getMyTruck: () => { return Promise.resolve({} as FTLTruck) },
  trucks: {},
  myTruck: undefined,
});

export function TruckProvider(props: { children: React.ReactNode }) {
  
  const [trucks, setTrucks] = useState<{[key: string]: FTLTruck}>({});
  
  const session = useSession()

  let myTruck;
  if (session.status === "authenticated" && trucks) {
    myTruck = trucks[session.data.user.truck];
  }

  const getTrucks = (market?: string): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(`/api/trucks?market=${market}`);
      const data = await response.json();
      if (data?.trucks) {
        const trucksObj = data.trucks.reduce((obj: {[key: string]: FTLTruck}, truck: FTLTruck) => {
          obj[truck.id] = truck;
          return obj;
        }, {});
        setTrucks(trucksObj);
      }

      resolve();
    });
  }

  const getTruck = (truckId: string): Promise<FTLTruck> => {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(`/api/truck/${truckId}`)
      const data = await response.json();
      if (data?.truck) {
        resolve(data.truck)
      } else {
        reject(data);
      }
    })
  }

  const getMyTruck = (): Promise<FTLTruck> => {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(`/api/my-truck`)
      const data = await response.json();
      if (data) {
        resolve(data)
      } else {
        reject(data);
      }
    })
  }

  return (
    <TrucksContext.Provider value={{
      getTrucks,
      getTruck,
      getMyTruck,
      trucks,
      myTruck
    }}>
      {props.children}
    </TrucksContext.Provider>
  )
}

export default TrucksContext;