import "../styles/main.scss";
import type { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";
import { DataProvider } from "../src/context/data";
import { CalendarProvider } from "../src/context/calendar";
import { TruckProvider } from "../src/context/trucks";
import { CategoryProvider } from "../src/context/categories";
import { RulesProvider } from "../src/context/rules";
import { Session } from "next-auth";
import { LoadingProvider } from "../src/context/loading";
import { ModalProvider } from "../src/context/modal";
import Modal from "../src/components/Modal";
import { useRouter } from "next/router";
import { InvoiceProvider } from "../src/context/invoice";
import "../styles/tailwind/globals.css";
import { Toaster } from "../src/components/ui/toaster";
import Script from "next/script";

interface MyAppProps {
  session: Session;
}

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<MyAppProps>) {
  const router = useRouter();

  return (
    <SessionProvider session={session}>
      <ModalProvider>
        <LoadingProvider>
          <TruckProvider>
            <CategoryProvider>
              <RulesProvider>
                <DataProvider>
                  <CalendarProvider>
                    <InvoiceProvider>
                      <Component {...pageProps} />
                      <Toaster />
                      <Modal />
                    </InvoiceProvider>
                  </CalendarProvider>
                </DataProvider>
              </RulesProvider>
            </CategoryProvider>
          </TruckProvider>
        </LoadingProvider>
      </ModalProvider>
    </SessionProvider>
  );
}
export default MyApp;
