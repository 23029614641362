import React, { useState, useEffect, useContext, useMemo } from "react";
import { FTLCategory, FTLEvent, FTLInvoice } from "../types";

const InvoiceContext = React.createContext<{
  getInvoice: (eventId: string, truckId: string) => Promise<any>,
  createInvoice: (eventId: string, truckId: string, sales: number) => Promise<any>,
  getPastInvoices: () => Promise<FTLInvoice[]>,
  getPastEventsWithoutInvoices: () => Promise<FTLEvent[]>,
}>({
  getInvoice: (eventId: string, truckId: string) => { return Promise.resolve() },
  createInvoice: (eventId: string, truckId: string, sales: number) => { return Promise.resolve() },
  getPastInvoices: () => { return Promise.resolve([]) },
  getPastEventsWithoutInvoices: () => { return Promise.resolve([]) },
});

export function InvoiceProvider(props: { children: React.ReactNode }) {

  const getInvoice = (eventId: string, truckId: string) => {
    return new Promise<any>(async (resolve, reject) => {
      const response = await fetch(`/api/invoice/${truckId}/${eventId}`);
      const data = await response.json();
      if (data?.invoice) {
        return resolve(data.invoice)
      }
      return resolve(null)
    });
  }

  const createInvoice = (eventId: string, truckId: string, sales: number) => {
    return new Promise<any>(async (resolve, reject) => {
      const response = await fetch(`/api/create-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          eventId,
          truckId,
          sales
        })
      })
      const data = await response.json();
      resolve({})
    });
  }

  const getPastInvoices = () => {
    return new Promise<FTLInvoice[]>(async (resolve, reject) => {
      const response = await fetch(`/api/past-invoices`);
      const data = await response.json();
      if (data?.invoices) {
        return resolve(data.invoices)
      }
      return resolve([])
    });
  }

  const getPastEventsWithoutInvoices = () => {
    return new Promise<FTLEvent[]>(async (resolve, reject) => {
      const response = await fetch(`/api/past-events-without-invoices`);
      const data = await response.json();
      if (data?.events) {
        return resolve(data.events)
      }
      return resolve([])
    });
  }

  return (
    <InvoiceContext.Provider value={{
      getInvoice,
      createInvoice,
      getPastInvoices,
      getPastEventsWithoutInvoices
    }}>
      {props.children}
    </InvoiceContext.Provider>
  )
}

export default InvoiceContext;
