import React, { useState, useEffect, useContext, useMemo, ReactNode } from "react";
import { FTLCategory } from "../types";

const ModalContext = React.createContext<{
  contents: any,
  showing: boolean,
  setContents: (content: any) => void,
  setShowing: (showing: boolean) => void,
}>({
  contents: null,
  showing: false,
  setContents: (content: any) => {},
  setShowing: (showing: boolean) => {},
});

export function ModalProvider(props: { children: ReactNode }) {
  const [showing, setShowing] = useState<boolean>(false);
  const [contents, setContents] = useState<any>(null);

  return (
    <ModalContext.Provider value={{
      contents,
      showing,
      setContents,
      setShowing,
    }}>
      {props.children}
    </ModalContext.Provider>
  )
}

export default ModalContext;
