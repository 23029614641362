import React, { useState, useEffect, useContext, useMemo } from "react";
import { FTLCategory } from "../types";

const CategoriesContext = React.createContext<{
  categories: {[key: string]: FTLCategory},
  getCategories: () => Promise<void>,
}>({
  categories: {},
  getCategories: () => { return Promise.resolve() },
});

export function CategoryProvider(props: { children: React.ReactNode }) {
  const [categories, setCategories] = useState<{[key: string]: FTLCategory}>({});

  const getCategories = () => {
    return new Promise<void>(async (resolve, reject) => {
      const response = await fetch('/api/categories');
      const data = await response.json();
      if (data?.categories) {
        const categoriesObj = data.categories.reduce((obj: {[key: string]: FTLCategory}, cat: FTLCategory) => {
          obj[cat.id] = cat;
          return obj;
        }, {});
        setCategories(categoriesObj);
      }
      resolve()
    });
  }

  return (
    <CategoriesContext.Provider value={{
      getCategories,
      categories,
    }}>
      {props.children}
    </CategoriesContext.Provider>
  )
}

export default CategoriesContext;
