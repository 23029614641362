import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  parse,
  parseISO,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import React, { useState, useEffect, useContext, useMemo } from "react";
import { FTLBooking, FTLEvent, Week } from "../types";
import DataContext from "./data";

const CalendarContext = React.createContext<{
  weeks: Week[];
  getWeeks: (date: Date) => void;
  selectedWeek?: Week;
  selectedBooking?: FTLBooking;
  displayedMonthDate: Date;
  setDisplayedMonthDate: (date: Date) => void;
  setSelectedBooking: (booking: FTLBooking) => void;
}>({
  weeks: [],
  getWeeks: (date: Date) => {},
  displayedMonthDate: new Date(),
  setDisplayedMonthDate: (date: Date) => {},
  setSelectedBooking: (booking: FTLBooking) => {},
});

export function CalendarProvider(props: { children: React.ReactNode }) {
  const { bookings } = useContext(DataContext);
  const [weeks, setWeeks] = useState<Week[]>([]);
  const [displayedMonthDate, setDisplayedMonthDate] = useState<Date>(
    new Date()
  );
  const [selectedBooking, setSelectedBooking] = useState<
    FTLBooking | undefined
  >(undefined);

  const getWeeks = (date: Date): Week[] => {
    const firstDay = startOfWeek(startOfMonth(date));
    const lastDay = endOfWeek(endOfMonth(date));

    let currentDate = firstDay;
    let weeks: Week[] = [];
    let currentRow = 0;
    while (isBefore(currentDate, lastDay)) {
      weeks.push([]);
      for (var i = 0; i < 7; i++) {
        const dayBookings = bookings?.filter((b: FTLBooking) => {
          const date = parse(b.date.split("T")[0], "yyyy-MM-dd", new Date());
          return format(date, "MdY") === format(currentDate, "MdY");
        });
        weeks[currentRow].push({
          date: currentDate,
          bookings: dayBookings?.length ? dayBookings : undefined,
        });
        currentDate = addDays(currentDate, 1);
      }
      currentRow++;
    }

    return weeks;
  };

  useEffect(() => {
    setWeeks(getWeeks(displayedMonthDate));
  }, [bookings, displayedMonthDate]);

  const selectedWeek = useMemo((): Week => {
    const selectedWeek = weeks.find((week: Week) => {
      return !!week.find(
        (day) => format(displayedMonthDate, "MdY") === format(day.date, "MdY")
      );
    });
    return selectedWeek || [];
  }, [weeks, bookings, displayedMonthDate]);

  useEffect(() => {
    if (selectedBooking && bookings) {
      const event = bookings.find((b) => b.id === selectedBooking.id);
      setSelectedBooking(event);
    }
  }, [selectedBooking, bookings]);

  return (
    <CalendarContext.Provider
      value={{
        getWeeks,
        weeks,
        selectedWeek,
        displayedMonthDate,
        setDisplayedMonthDate,
        selectedBooking,
        setSelectedBooking,
      }}
    >
      {props.children}
    </CalendarContext.Provider>
  );
}

export default CalendarContext;
