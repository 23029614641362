import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  parseISO,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { useSession } from "next-auth/react";
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { FTLBooking, FTLEvent, FTLEventV2, FTLLocation, Week } from "../types";
import RulesContext from "./rules";

const DataContext = React.createContext<{
  bookings: FTLBooking[];
  allBookings: FTLBooking[];
  getEventsV2: (market?: string) => Promise<FTLEventV2[]>;
  eventsV2: FTLEventV2[];
  allEventsV2: FTLEventV2[];
  getBookings: (market?: string) => Promise<FTLBooking[]>;
  getBooking: (eventId: string) => Promise<FTLBooking>;
  scheduleBooking: (booking: FTLBooking) => Promise<void>;
  unscheduleBooking: (booking: FTLBooking) => Promise<void>;
  sendTextInvite: (booking: FTLBooking) => Promise<void>;
  excludedEventsV2: string[];
  excludeEventV2: (eventV2Id: string) => void;
  includeEventV2: (eventV2Id: string) => void;
  setExcludedEventsV2: (excludedEventsV2: string[]) => void;
  filterByType: (type: "lunch" | "dinner") => void;
  setShowMyEventsOnly: (show: boolean) => void;
}>({
  bookings: [],
  allBookings: [],
  eventsV2: [],
  allEventsV2: [],
  getEventsV2: (market?: string) => {
    return Promise.resolve([]);
  },
  getBookings: (market?: string) => {
    return Promise.resolve([]);
  },
  getBooking: (eventId: string) => {
    return Promise.resolve({} as FTLBooking);
  },
  scheduleBooking: (booking: FTLBooking) => {
    return Promise.resolve();
  },
  unscheduleBooking: (booking: FTLBooking) => {
    return Promise.resolve();
  },
  sendTextInvite: (booking: FTLBooking) => {
    return Promise.resolve();
  },
  excludedEventsV2: [],
  excludeEventV2: (eventV2Id: string) => {},
  includeEventV2: (eventV2Id: string) => {},
  setExcludedEventsV2: (excludedEventsV2: string[]) => {},
  filterByType: (type: "lunch" | "dinner") => {},
  setShowMyEventsOnly: (show: boolean) => {},
});

export function DataProvider(props: { children: React.ReactNode }) {
  const session = useSession();
  const rulesContext = useContext(RulesContext);

  const [excludedEventsV2, setExcludedEventsV2] = useState<string[]>([]);
  const [allEventsV2, setAllEventsV2] = useState<FTLEventV2[]>([]);
  const [bookings, setBookings] = useState<FTLBooking[]>([]);
  const [showMyEventsOnly, setShowMyEventsOnly] = useState<boolean>(false);

  const truckId: string = useMemo(() => {
    return session?.data?.user?.truck || "";
  }, [session]);

  const getEventsV2 = (market?: string) => {
    return new Promise<FTLEventV2[]>(async (resolve, reject) => {
      const response = await fetch(
        `/api/events-v2${market ? `?market=${market}` : ""}`
      );
      const data = await response.json();
      if (data?.events) {
        setAllEventsV2(data.events);
      }
      resolve(data?.events);
    });
  };

  const getBookings = (market?: string) => {
    return new Promise<FTLBooking[]>(async (resolve, reject) => {
      const response = await fetch(
        `/api/bookings${market ? `?market=${market}` : ""}`
      );
      const data = await response.json();
      if (data?.bookings) {
        setBookings(data.bookings);
      }
      resolve(data.bookings);
    });
  };

  const getBooking = (id: string) => {
    return new Promise<FTLBooking>(async (resolve, reject) => {
      const response = await fetch(`/api/booking/${id}`);
      const data = await response.json();
      if (data?.booking) {
        resolve(data.booking);
      }
      reject();
    });
  };

  const scheduleBooking = async (booking: FTLBooking) => {
    return new Promise<void>(async (resolve, reject) => {
      const response = await fetch("/api/schedule-booking", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          booking,
        }),
      });
      const data = await response.json();

      if (data.success) {
        await getBookings();
      }

      resolve();
    });
  };

  const unscheduleBooking = async (booking: FTLBooking) => {
    return new Promise<void>(async (resolve, reject) => {
      const response = await fetch("/api/unschedule-booking", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          booking,
        }),
      });
      const data = await response.json();

      if (data.success) {
        await getBookings();
      }

      resolve();
    });
  };

  const sendTextInvite = async (booking: FTLBooking) => {
    return new Promise<void>(async (resolve, reject) => {
      const response = await fetch("/api/send-text-invite", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          booking,
        }),
      });
      const data = await response.json();

      if (data.success) {
        await getBookings();
      }

      resolve();
    });
  };

  const eventsV2: FTLEventV2[] = useMemo(() => {
    if (!!rulesContext?.truckCountRules && !!bookings) {
      const eventV2IdsWithRules = allEventsV2
        .filter((eventV2) => {
          return !!rulesContext.truckCountRules[`${eventV2.id}-${truckId}`];
        })
        .map((eventV2) => {
          return eventV2.id;
        });

      const eventV2IdsWithBookings = bookings
        .filter((booking) => {
          return booking.scheduledTrucks.includes(truckId);
        })
        .map((booking) => {
          return booking.event;
        });

      const uniqueEventV2Ids = [
        ...new Set([...eventV2IdsWithRules, ...eventV2IdsWithBookings]),
      ];

      return allEventsV2.filter((e) => {
        return uniqueEventV2Ids.includes(e.id);
      });
    }
    return allEventsV2;
  }, [allEventsV2, rulesContext?.truckCountRules, bookings, truckId]);

  const filteredEventsV2 = useMemo(() => {
    return eventsV2.filter((e) => {
      return !excludedEventsV2.includes(e.id);
    });
  }, [eventsV2, excludedEventsV2]);

  const filteredBookings = useMemo(() => {
    const newEvents = bookings.filter((booking) => {
      return filteredEventsV2.map((e) => e.id).includes(booking.event);
    });
    if (showMyEventsOnly) {
      return newEvents.filter((event) => {
        return event.scheduledTrucks.includes(truckId);
      });
    }
    return newEvents;
  }, [bookings, filteredEventsV2, showMyEventsOnly]);

  const excludeEventV2 = useCallback(
    (id: string) => {
      setExcludedEventsV2((eventsV2) => {
        return [...eventsV2, id];
      });
    },
    [excludedEventsV2, setExcludedEventsV2]
  );

  const includeEventV2 = useCallback(
    (id: string) => {
      setExcludedEventsV2((eventsV2) => {
        return eventsV2.filter((e) => {
          return e !== id;
        });
      });
    },
    [excludeEventV2, setExcludedEventsV2]
  );

  const filterByType = useCallback(
    (type: "lunch" | "dinner") => {
      setExcludedEventsV2(
        eventsV2.filter((e) => e.type !== type).map((e) => e.id)
      );
    },
    [eventsV2, excludedEventsV2]
  );

  return (
    <DataContext.Provider
      value={{
        getEventsV2,
        eventsV2,
        allEventsV2,
        getBookings,
        getBooking,
        bookings: filteredBookings,
        allBookings: bookings,
        scheduleBooking,
        unscheduleBooking,
        sendTextInvite,
        excludedEventsV2,
        excludeEventV2,
        includeEventV2,
        setExcludedEventsV2,
        filterByType,
        setShowMyEventsOnly,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
}

export default DataContext;
