import React, { FC, useContext } from 'react'
import ModalContext from '../context/modal';

const Modal: FC<any> = (props) => {
  const { showing, contents } = useContext(ModalContext)
  return (
    <div className={'modal-background' + (!showing ? ' hidden' : '')}>
      <div className='modal-container'>
        {contents}
      </div>
    </div>
  )
}

export default Modal;

// export class Modal extends React.Component {
//   render () {
//     return (
      // <div className={'modal-background' + (!this.props.modal.showing ? ' hidden' : '')}>
      //   <div className='modal-container'>
      //     { this.props.modal.contents }
      //   </div>
      // </div>
//     )
//   }
// }

// Modal.propTypes = {
//   modal: PropTypes.shape({
//     showing: PropTypes.bool,
//     contents: PropTypes.object,
//   }),
// }

// function mapStateToProps (state) {
//   return {
//     modal: state.modal,
//   }
// }

// function mapDispatchToProps (dispatch) {
//   return bindActionCreators({ }, dispatch)
// }

// export default connect(mapStateToProps, mapDispatchToProps)(Modal)
