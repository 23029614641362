import React, { useState, useEffect, useContext, useMemo } from "react";
import { FTLCategory } from "../types";

const LoadingContext = React.createContext<{
  loading: boolean;
  message1: string;
  message2: string;
  showLoading: (m1?: string, m2?: string) => void;
  hideLoading: () => void;
}>({
  loading: false,
  message1: "",
  message2: "",
  showLoading: (m1?: string, m2?: string) => {},
  hideLoading: () => {},
});

export function LoadingProvider(props: { children: React.ReactNode }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [message1, setMessage1] = useState<string>("");
  const [message2, setMessage2] = useState<string>("");

  const showLoading = (m1?: string, m2?: string) => {
    if (m1) {
      setMessage1(m1);
    }
    if (m2) {
      setMessage2(m2);
    }
    setLoading(true);
  };
  const hideLoading = () => {
    setLoading(false);
    setMessage1("");
    setMessage2("");
  };

  return (
    <LoadingContext.Provider
      value={{
        message1,
        message2,
        loading,
        showLoading,
        hideLoading,
      }}
    >
      {props.children}
    </LoadingContext.Provider>
  );
}

export default LoadingContext;
